import { logEvent } from "firebase/analytics";
import { analytics } from './firebase';

// scrollTracker.js
export const setupScrollTracking = () => {
    let lastScrollDepth = 0;
    let hasScrolled = {
        25: false,
        50: false,
        75: false
    }; // Object to track scroll percentages

    const logScroll = (percentage) => {
        hasScrolled[percentage] = true; // Mark as logged immediately

        setTimeout(() => {
            logEvent(analytics, 'scroll_depth', {
                percent_scrolled: percentage
            });
        }, 100); // Delay of 100 milliseconds (adjust as needed)
    }

    const reportScrollDepth = () => {
        const scrollDepth = (window.scrollY + window.innerHeight) / document.documentElement.scrollHeight;
        const percentageScrolled = scrollDepth * 100;
        if (percentageScrolled < lastScrollDepth) {
            return
        }

        if (percentageScrolled >= 25 && !hasScrolled[25]) {
            lastScrollDepth = percentageScrolled
            logScroll(25)
        } else if (percentageScrolled >= 50 && !hasScrolled[50]) {
            lastScrollDepth = percentageScrolled
            logScroll(50)
        } else if (percentageScrolled >= 75 && !hasScrolled[75]) {
            lastScrollDepth = percentageScrolled
            logScroll(75)
        }
    };

    window.addEventListener('scroll', reportScrollDepth);
    return reportScrollDepth
};

export const cleanScrollListener = (listener) => {
    if (listener) {
        window.removeEventListener('scroll', listener);
    }
}    