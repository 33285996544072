import { initializeApp } from "firebase/app";
import { getAnalytics, setAnalyticsCollectionEnabled } from "firebase/analytics";

import { initializeAppCheck, ReCaptchaV3Provider, getToken } from "firebase/app-check";

const firebaseConfig = {
    apiKey: "AIzaSyBSyHZtRElIcUIAB--ZO-N25IvSutYrYq4",
    authDomain: "papaty-896e3.firebaseapp.com",
    projectId: "papaty-896e3",
    storageBucket: "papaty-896e3.appspot.com",
    messagingSenderId: "592639839736",
    appId: "1:592639839736:web:d7204d2883d646cffa5a0d",
    measurementId: "G-2M2M6Z6R5M"
  };

const app = initializeApp(firebaseConfig);
const captchaKey = '6LdYSNwoAAAAAPtJj1JtFWm8QgygwpmaBcmSZlxt'

if (process.env.NODE_ENV === 'development') {
  // enable for debug mode
  console.log("setting debug mode")
  self.FIREBASE_APPCHECK_DEBUG_TOKEN = true;
}

export let appCheck = null 

export const analytics = getAnalytics(app);
setAnalyticsCollectionEnabled(analytics, false)

const FB_BASE_URL = `https://firestore.googleapis.com/v1/projects/papaty-896e3/databases/(default)/documents`;

const sendToFireBaee = async (collectionName, data) => {
  if(!appCheck) {
    appCheck = initializeAppCheck(app, {
      provider: new ReCaptchaV3Provider(captchaKey),
      // Optional - set to true to allow running in an environment like localhost
      isTokenAutoRefreshEnabled: true
    });
  }
  const collectionUrl = `${FB_BASE_URL}/${collectionName}`

  const appCheckToken = await getToken(appCheck, false)
  const response = await fetch(collectionUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Firebase-AppCheck': appCheckToken.token
    },
    body: JSON.stringify(data)
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  await response.json();
}

export const subscribe = async (socialId, socialNetwork) => {
  const subsciptionsCol = 'subscriptions'

  if(!socialId) {
    console.log('skipping subscribing. socialId is null')
    return
  }

  const data = {
    fields: {
      socialId: { stringValue: socialId.trim() },
      socialNetwork: { stringValue: socialNetwork.trim() },
      createdAt: { timestampValue: new Date().toISOString() } // Firestore compatible date format
    }
  }

  await sendToFireBaee(subsciptionsCol, data)
}

export const sendMessage = async (name, email, message) => {
  const contactUsCol = 'contactUs'

  if(!name || !email || !message) {
    console.log('skipping sending message. name/mail or message is empty', name, email, message)
    return
  }

  const data = {
    fields: {
      name: { stringValue: name.trim() },
      email: { stringValue: email.trim() },
      message: { stringValue: message.trim() },
      createdAt: { timestampValue: new Date().toISOString() } // Firestore compatible date format
    }
  }

  await sendToFireBaee(contactUsCol, data)
}


export default app;
