// ConsentContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';
import { setConsent as setAnalyticsConsent, setAnalyticsCollectionEnabled } from 'firebase/analytics';
import { getQueryParam, isTruthy } from "./utils";

import { logEvent } from "firebase/analytics";
import { analytics } from './firebase'

export const CONSENT_KEY = 'userAcceptedCookies'
const ConsentContext = createContext();

const CONSENT_VALS_EVENTS = {
  accepted: 'consent_accepted',
  rejected: 'consent_rejected'
}

const reportConsent = (accepted) => {
  const consentValue = accepted === true ? CONSENT_VALS_EVENTS.accepted : CONSENT_VALS_EVENTS.rejected
  try {
    logEvent(analytics, consentValue)
  } catch (e) {
    console.error(e)
  }
}

const SKIP_CONSENT_KEY = 'nc'
const defaultSkipCol = false // This value indicates the default consent collection if no histoy/param

export const CONSENT_VALUES = {
  SKIPPED: 'skipped',
  GRANTED: 'granted',
  DENIED: 'denied'
}
export const useConsent = () => useContext(ConsentContext);

export const ConsentProvider = ({ children }) => {
  const skipConCol = isTruthy(getQueryParam(SKIP_CONSENT_KEY)) || defaultSkipCol
  const defaultConsent = skipConCol ? CONSENT_VALUES.SKIPPED : CONSENT_VALUES.DENIED
  const [consent, setConsent] = useState(defaultConsent);

  useEffect(() => {
    const userConsent = localStorage.getItem(CONSENT_KEY);
    // we only store granted if user accept cookies
    if (userConsent) {
        grantConsent()
    } else {
      setAnalyticsConsent(analytics, {
        // grants
        'security_storage': 'granted'
    });
    }

    if (skipConCol) {
      setAnalyticsCollectionEnabled(analytics, true)
    }

  }, []);

  const grantConsent = () => {
    setAnalyticsCollectionEnabled(analytics, true)
    setAnalyticsConsent(analytics, {
        'ad_storage': 'denied',
        // grants
        'ad_user_data': 'granted',
        'ad_personalization': 'granted',
        'analytics_storage': 'granted',
        'functionality_storage': 'granted',
        'personalization_storage': 'granted',
        'security_storage': 'granted'
    })
    localStorage.setItem(CONSENT_KEY, CONSENT_VALUES.GRANTED);
    setConsent(CONSENT_VALUES.GRANTED);
    reportConsent(true)
  };

  const denyConsent = () => {
    console.log('denying consent')
    reportConsent(false)
    setAnalyticsConsent(analytics, {
        'ad_storage': 'denied',
        'ad_user_data': 'denied',
        'ad_personalization': 'denied',
        'analytics_storage': 'denied',
        'functionality_storage': 'denied',
        'personalization_storage': 'denied',
        // grants
        'security_storage': 'granted'
    });
    setAnalyticsCollectionEnabled(analytics, true)

    localStorage.removeItem(ConsentContext)
    setConsent(CONSENT_VALUES.DENIED);
  };

  return (
    <ConsentContext.Provider value={{ consent, grantConsent, denyConsent }}>
      {children}
    </ConsentContext.Provider>
  );
};
