import React from 'react';

const YouTubeEmbed = (props) => {
    const mute = props.mute ? 1 : 0
    const videoId = "SvqlCjPJf3I"
    // Construct YouTube embed URL

    const embedUrl = `https://www.youtube.com/embed/${videoId}?&showinfo=0&autoplay=${1}&rel=0&showinfo=0&cc_lang_pref=en&cc_load_policy=1&mute=${mute}&enablejsapi=1`;

    return (
            <iframe
                src={embedUrl}
                style={{
                    overflow: 'hidden',
                    position: 'relative',
                    height: '670px', // Set height
                }}
                loading="lazy"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                title="Embedded YouTube Video"
            ></iframe>
    );
};

export default YouTubeEmbed;