
import React from "react";
import {
  useBreakpointValue,
  VStack, Text, Heading, Image, Flex, View,
} from "native-base";

import { getLanguageFromUrl } from "./utils";
import translations from './translations.json';
import { useLanguage } from './LanguageContext'

const AppScreen = (props) => {
  const {image, text } = props
  return  <VStack space={4} alignItems="center" flex={1} mb={4}>
  <Image h={{ base: 500, lg: 700 }} resizeMode="contain" w="100%"
      source={image} alt="Sustainable Fashion"
      mb={{base: 2, lg: 0}}
    />
    <Text color="black" fontWeight="bold" fontSize={15} textAlign="center" px={2}>{text}</Text>

    </VStack>
}

export default function AppUxScreens(props) {
    const flexDir = useBreakpointValue({
      base: "column",
      lg: "row"
    });

    const { language } = useLanguage();
    const lang = language || getLanguageFromUrl()
    let { title, items } = translations['AppUxScreens']?.[lang] || props
    items = items ?? AppUxScreens.defaultProps.items
    title = title ?? AppUxScreens.defaultProps.title

    const { colors } = props

    const images = [
      require('./assets/landing/Shop.webp'),
      require('./assets/landing/Ranking.webp'),
    ]

    return <VStack alignItems="center">
      <Heading color={colors.heading} fontSize={{ base: 20, lg: 30 }}
        bg={colors.bgText} mb={10} p={{ base: 2, lg: 5}}
        textAlign={{base: 'center', lg: 'start'}}
        fontWeight="500">{title}
      </Heading>
      <Flex direction={flexDir} justifyContent="space-between" w="100%" flex={2} >
        { 
        images.map((image, index) => ( 
         <AppScreen image={image} text={items[index]} key={index}/>

        ))
        }

      </Flex>
    </VStack>
  
  }

AppUxScreens.defaultProps = {
    colors: {
        heading: 'tertiary.500',
        bgHeading: 'transparent'
    },
    title: 'Your sustainable fashion marketplace',
    items: [
      "Buy your favourit gem with open heart",
      "Keep Track of your contribution to our Planet"
    ]
}