import React, { useState } from 'react';
import { VStack, HStack, Text, Button, Link, Icon } from 'native-base';
import { FontAwesome5 } from '@expo/vector-icons';

import PrivacyPolicyModal from './PrivacyPolicy'
const FooterComponent = (props) => {
    const { bg, colorScheme } = props
    const [showModal, setShowModal] = useState(false);

    return (
        <VStack
            bg={bg}
            py={4}
            px={6}
            alignItems="center"
            justifyContent="space-between"
        >
            <HStack space={4}>
                <Link href="mailto:career@stylu.de">

                    <Button size="sm" variant="ghost" colorScheme={colorScheme} >
                        Jobs
                    </Button>
                </Link>
                <Link href="mailto:info@stylu.de">

                    <Button size="sm" variant="ghost" colorScheme={colorScheme} >
                        Press
                    </Button>
                </Link>


                <Button size="sm" variant="ghost" colorScheme={colorScheme} onPress={() => setShowModal(true)}>
                    Privacy Policy
                </Button>

                <Link href="mailto:partners@stylu.de">

                    <Button size="sm" variant="ghost" colorScheme={colorScheme} >
                        Partners
                    </Button>
                </Link>

            </HStack>

            <HStack space={4} mt={4}>

                <Link href="https://www.instagram.com/stylu_vintage_family/">


                    <Button variant="ghost" colorScheme={colorScheme} leftIcon={<Icon as={FontAwesome5}
                        variant="ghost" name="instagram" size="lg" />} />
                </Link>

                <Link href="https://www.instagram.com/stylu_vintage_family/">


                    <Button variant="ghost" colorScheme={colorScheme} leftIcon={<Icon as={FontAwesome5}
                        variant="ghost" name="tiktok" size="lg" />} />
                </Link>


            </HStack>
            <PrivacyPolicyModal showModal={showModal} onClose={() => setShowModal(false)}/>
            <Text color="blueGray.800" mt={4}>&copy; 2024 Stylue, Inc. All rights reserved.</Text>
        </VStack>
    );
};

export default FooterComponent;
