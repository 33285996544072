import React from "react";

import {
  useBreakpointValue,
  VStack, Text, Heading, Avatar
} from "native-base";

import { getLanguageFromUrl} from "./utils";
import translations from './translations.json';
import { useLanguage } from './LanguageContext';


export default function About(props) {

  const { language } = useLanguage();
  const lang = language || getLanguageFromUrl()
  const { title, paragraphs } =  translations['About'][lang] || props
    const { colors, textWidth } = props
    const avatarSize = useBreakpointValue({
      base: "200",
      lg: "400"
    })
    const textalign = useBreakpointValue({
      base: "center",
      lg: "center"
    })
  
    return <VStack w="100%" py={5} space={5} alignItems="center">

      <Heading color={colors.heading} fontSize={{ base: 20, lg: 30 }}
        bg={colors.bgText} mb={10} p={5}
        textAlign='center'
        w={{base: "100%", lg: "50%"}}
        fontWeight="500">{title}
      </Heading>


    <Avatar bg="purple.600" alignSelf="center" size={avatarSize} source={require('./assets/landing/ams_1.webp')}>
    </Avatar>

      {paragraphs.map((p, i) => (
        <Text px={2} key={i} fontSize={{ base: "lg", lg: "xl" }} color="muted.500" textAlign={textalign} width={textWidth}>
          {p}
        </Text>
      ))}
  </VStack>
  }
  
  About.defaultProps = {
    colors: {
        heading: 'tertiary.500',
        bgHeading: 'transparent'
    },
    textWidth: '100%',
    title: 'Be the solution not the polution! :)',
    parapgraphs: [
      "We are a small startup based in Amsterdam with the vision of transforming the fashion landscape into a more sustainable and eco-conscious one. Stylus was born from our passionate belief that every garment deserves a second chance and that fashion doesnt have to cost the earth."
    ]
  }