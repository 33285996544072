import React, { useState, useEffect } from 'react';
import { Box, Divider, Button, Text, Heading, VStack, Link, HStack, ScrollView } from 'native-base';
import { useConsent } from './ConsentContext'

const PrivacyPolicyModal = (props) => {

    const [showModal, setShowModal] = useState(false);
    const { denyConsent } = useConsent();


    useEffect(() => {
        setShowModal(props.showModal)
    }, [props.showModal])

    const closeModal = () => {
        props.onClose()
        setShowModal(false)
    }

    if (!showModal) {
        return null;
    }

    return (
        <Box w="100%" h="100%" rounded="2xl" overflow="hidden" borderColor="coolGray.200" borderWidth="1" _web={{
            shadow: 2,
            borderWidth: 0
        }}
            shadow="6" position="fixed" zIndex="overlay" bottom="0" left="0" right="0"
            alignItems="center"
        >
            <ScrollView h="100%" w={{ base: "100%", md: "70%" }}>
                <VStack space={4} py={5} px={{ base: 3, md: 5 }} bg="gray.100" h="100%" w="100%">
                    <HStack justifyContent="space-between" alignItems="center" maxH={20}>
                        <Heading size="lg">Privacy Policy</Heading>
                        <Button size="xs" onPress={closeModal} colorScheme="gray">
                            X
                        </Button>
                    </HStack>
                    <VStack space={2}>

                        <Text>
                            Welcome to Stylu, your partner in sustainable fashion. At Stylu, we're dedicated to transforming your wardrobe into an eco-friendly powerhouse, all while keeping our planet in mind. Our commitment to sustainability extends to how we handle your data. This Privacy Policy outlines our practices and your choices regarding your personal information. Understand how we collect, use, and protect your data, ensuring your journey with us is not just green but also safe and transparent. Have questions? Reach out anytime at
                            <Link ml={1} href="https://stylu.de">
                                https://stylu.de.
                            </Link>
                        </Text>
                        <Text>- We clearly state the purposes for which we process personal data. This is done through this data protection declaration.</Text>
                        <Text>- We will first obtain your explicit consent if this is necessary to process your personal data.</Text>
                        <Text>- We take appropriate security measures to protect your personal data and require the same from parties who process personal data on our behalf.</Text>
                        <Text>- We respect your right to access, correct or delete your personal data.</Text>

                        <Text> If you have any questions please feel free to reach out to us via email or our contact form.
                        </Text>
                    </VStack>
                    <Divider my="2" />
                    <VStack space={3} p={4}>
                        <Heading size="md">1. Information We Collect</Heading>

                        <Text>
                            We collect the following information from you:
                        </Text>
                        <VStack>
                            <Text>
                                <Text bold>- Non-Personally Identifiable Information (Non-PII): </Text> This information does not identify you as a specific individual. It may include information about your device, browsing activity, operating system, and general location data. We collect this information through Google Analytics (with consent as essential cookies).
                            </Text>
                            <Text mt={1}>
                                <Text bold>- Personally Identifiable Information (PII): </Text> This information can identify you as a specific individual. It may include information you provide us when you choose to personalize your experience on our Site, such as your name, email address, or preferences. We will only collect this information with your explicit consent.
                            </Text>
                        </VStack>


                        <Heading size="md">2.1 Use of Non-Personally Identifiable Information (Non-PII)</Heading>
                        <VStack>
                            <Text >
                                - Understand how visitors use our Site
                            </Text>
                            <Text mt={1}>
                                - Improve the performance and user experience of our Site.
                            </Text>
                            <Text mt={1}>
                                - Analyze trends and user behavior..
                            </Text>
                        </VStack>


                        <Heading size="md">2.2 Use of Personally Identifiable Information (PII) (With Consent)</Heading>
                        <VStack>
                            <Text mt={1}>
                                - If consented, deliver targeted advertising (if applicable). Remarketing with Google Analytics. Google Analytics Demographics and Interest Reporting. Integrated services that require Google Analytics to collect data for advertising purposes, including the collection of data via advertising cookies and identifiers
                            </Text>

                            <Text mt={1}>
                                - Send you marketing communications, if you subscribe through our subcription form.
                            </Text>
                        </VStack>

                        <Heading size="md">3. Disclosure of Your Information</Heading>
                        <Text>
                            We will not disclose your personal information to any third party without your consent. We will not share your PII with third parties for advertising purposes without your explicit consent. We may share your Non-PII with third-party service providers who help us operate and improve our Site. These service providers are contractually obligated to keep your information confidential
                        </Text>

                        <Heading size="md">4. Data Retention</Heading>
                        <Text>
                            We will retain your personal information for as long as necessary to fulfill the purposes for which it was collected, or as required by law.
                        </Text>

                        <Heading size="md">5. Data Transfer</Heading>
                        <Text>
                            Your information may be processed and stored in servers located outside of your country of residence, including in the United States and other jurisdictions with data protection laws that may differ from those of your jurisdiction.                        </Text>

                        <Heading size="md">6. Your Rights</Heading>
                        <Text>
                            Under GDPR, you have various rights, including:.
                        </Text>
                        <VStack space={1}>
                            <Text>
                                <Text bold>- Access: </Text>You have the right to access your personal information that we hold.
                            </Text>
                            <Text>
                                <Text bold>- Correction: </Text>You have the right to request that we correct any inaccurate personal information that we hold about you.
                            </Text>
                            <Text>
                                <Text bold>- Erasure: </Text>You have the right to request that we erase your personal information.
                            </Text>
                            <Text>
                                <Text bold>- Objection: </Text>You have the right to object to the processing of your personal information.
                            </Text>
                            <Text>
                                <Text bold>- Restriction: </Text>You have the right to request that we restrict the processing of your personal information.
                            </Text>
                        </VStack>

                        <Heading size="md">6. Changes to This Privacy Policy</Heading>
                        <Text>
                            We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on the Website.
                        </Text>

                        <Heading size="md">7 Revoke Your Consent</Heading>
                        <Text>
                            You can anytime revoke your consent either by deleting cookies and browsing history for this website or simply revoke consent by this button. You furthermore, can delete browing data for this domain. Reach out to your browsers guide on how to clear browing data for a specific domain.
                        </Text>
                        <Button size="md" onPress={() => denyConsent()} colorScheme="gray">
                            Revoke
                        </Button>

                        <Heading size="sm">7.1 Opting Out of Remarketing</Heading>
                        <Text>
                            We respect your right to control your data. If you do not want us to use your information for remarketing purposes, you can opt-out in several ways:

                            Browser Cookie Settings: Most web browsers allow you to manage cookies stored on your device. You can typically find cookie settings within your browser's privacy or security options. Refer to your browser's specific documentation for detailed instructions on managing cookies.
                            Google Ads Settings: You can visit the Google Ads Settings page
                            <Link ml={1} href="https://adssettings.google.com/">
                                https://adssettings.google.com/.
                            </Link>
                            to manage how your data is used for advertising across websites and services that use Google's advertising services. This includes opting out of remarketing with Google Analytics.
                            By opting out, you will not see personalized advertisements from Stylu on other websites.
                        </Text>


                        <Heading size="md">8. Contact Us</Heading>
                        <Text>
                            If you have any questions about this Privacy Policy, please contact us at
                            <Link href="mailto:info@stylu.de">
                                info@yourdomain.com.
                            </Link>
                        </Text>
                    </VStack>
                    <Button size="md" onPress={closeModal} colorScheme="gray">
                        Close
                    </Button>
                </VStack>
            </ScrollView>
        </Box>
    )
};

export default PrivacyPolicyModal;
