import React, { useState, useEffect, Suspense } from 'react';
import { View } from 'react-native'; // or from 'native-base' if using UI components from Native Base
import { Image, Pressable, Box, Button, Icon, Center } from 'native-base';
import FontAwesome5 from '@expo/vector-icons/FontAwesome5';
import { useLanguage } from '../LanguageContext';

import YoutubePlayer from './YoutubePlayer'
import { getQueryParam } from "../utils";

const VideoPlayerThumbnail = (props) => {

    const imageSources = {
        en: require('./youtube-thumbnail_en.webp'),
        nl: require('./youtube-thumbnail_nl.webp'),
        de: require('./youtube-thumbnail_de.webp'),
      };

      const imageSource = imageSources[props.lang] || imageSources['en']; // Default to English if undefined

    

    return <Pressable style={{ width: '100%', height: "470px", overflow: 'hidden' }}
    onPress={props.onPress || (() => { })}
>
    {({
        isPressed
    }) => {
        return <Box style={{
            transform: [{
                scale: isPressed ? 0.96 : 1
            }],
            width: '100%', height: '100%'
        }}
        backgroundColor={"gray.900"}
        onPress={props.onPress}
        >

            <Image
                source={imageSource} alt="Sustainable Fashion" resizeMode="contain"
                style={{ width: '100%', height: '100%', position: 'absolute' }}
                zIndex={1}

            />


            {props.loading ?
                <Spinner accessibilityLabel="Loading posts" /> :

                <Button
                colorScheme="red"
                backgroundColor="red.500" // NativeBase color palette for a consistent red
                zIndex={2}
                size="80px"
                px={4}
                py={2}
                borderRadius={8}
                position="absolute"
                alignSelf="center"
                top="50%"
                onPress={props.onPress}
              >
                <Icon
                  as={FontAwesome5}
                  name="youtube"
                  color="white" // Set icon color to white for visibility
                  size="50px"
                />
              </Button>


            }

        </Box>
    }}
</Pressable>
}

const LazyVideoLoader = () => {
    // const [YoutubePlayer, setYoutubePlayer] = useState(null);
    const [loadVideoPlayer, setLoadVideoPlayer] = useState(false);
    const { lang } = useLanguage();
    const autoplay = getQueryParam('ap');

    useEffect(() => {
        if (autoplay) {
            setLoadVideoPlayer(true)
        }

    }, [autoplay]);

    return (
        <View style={{ height: '470px', width: '100%', position: 'relative' }}>
            {loadVideoPlayer ? (
                <Suspense fallback={<VideoPlayerThumbnail loading={true} />}>
                    <YoutubePlayer mute={autoplay} />
                </Suspense>
            ) : (
                <VideoPlayerThumbnail lang={lang} onPress={() => setLoadVideoPlayer(true)} loading={false} />
            )}
        </View>
    );
};

export default LazyVideoLoader;
