import React from "react";
import {
  VStack, Heading, Image,Box, useBreakpointValue
} from "native-base";

import { getLanguageFromUrl } from "./utils";
import translations from './translations.json';
import { useLanguage } from './LanguageContext'

export default function AppFlowShowCase(props) {
  const { language } = useLanguage();
  const lang = language || getLanguageFromUrl()
    const { title } = translations['AppFlowShowCase']?.[lang] || props
    const { colors, tagId } = props

   return <VStack alignItems="center" nativeID={tagId}>

      <Heading color={colors.heading} fontSize={{ base: 20, lg: 30 }}
        bg={colors.bgText} mb={{base: 4, lg: 10}} p={5}
        textAlign='center'
        w={{base: "100%", lg: "50%"}}
        fontWeight="500">{title}
      </Heading>
  
      <Box h={{ base: 500, lg: 800 }} w="100%">
        <Image w="100%" h="100%" resizeMode="contain"
          source={require('./assets/landing/Story.webp')} alt="Sustainable Fashion"
        />
      </Box>
    </VStack>
  }

AppFlowShowCase.defaultProps = {
    colors: {
        heading: 'tertiary.500',
        bgHeading: 'transparent'
    },
    title: 'Buy your favourit gem with open heart',
}