
import React from "react";
import {
    useBreakpointValue,
    VStack, Text, Image, Flex, Box, Button
} from "native-base";

import { getLanguageFromUrl } from "./utils";
import translations from './translations.json';
import { useLanguage } from './LanguageContext';
import SignUp from "./SignUp";

export default function MinimalHero(props) {
    const { language } = useLanguage();
    const lang = language || getLanguageFromUrl()
    const { title, subtitle, learn } = translations['MinimalHero'][lang] || props;
    const flexDir = useBreakpointValue({
        base: "column",
        lg: "row"
    });

    const { learnId, colors } = props

    const isMobile = useBreakpointValue({
        base: true,
        lg: false
    })

    const isDesktop = !isMobile

    const landingBg = require('./assets/landing/landing-bg.webp')
    const landingGif = require('./assets/landing/vision-opt.gif')

    return <Flex minH={{ base: 750, lg: 650 }} bg={"white"} direction={"row"} justifyContent="start">

        <Flex zIndex={2} space={2} top="0%" left={"0"} w="100%" h="100%" position={"absolute"} direction={flexDir} alignItems={{ base: "center", lg: "start" }} justifyContent="flex-start">
            <VStack h={{ base: "30%", lg: "100%" }}
                minH="235px"
                backgroundColor={"white"}
                width={{ base: "100%", lg: "50%" }}
                alignItems={"center"}
                justifyContent="center">

                <Text mt={{ base: 3, lg: 0 }} color="black" fontWeight={"bold"} w="75%" textAlign="center" fontSize={24}>{title}</Text>

                <Text mt={{ base: 0, lg: 0 }} color="black" fontWeight={"light"} w="85%" textAlign="center" fontSize={22}>{subtitle}</Text>
                {/* 
                <Text mt={{ base: 6, lg: 0 }} color="gray.900" w="85%" textAlign="center" fontSize={12}>🌟 Buy & sell pre-loved fashion with 0 commission</Text>
                <Text mt={{ base: 2, lg: 0 }}  color="gray.900" w="85%" textAlign="center" fontSize={12}>🌟 Track your impact on the 🌍 alogn the way</Text>

                <Text mt={{ base: 5, lg: 0 }}  color="gray.900" w="85%" fontWeight={"bold"} textAlign="center" fontSize={16}>Join our Vision of saving 1K tons of 💧 and 10 tons of CO2 per YEAR</Text>
                 */}
                 {isDesktop && <Box w="100%" p={0}><SignUp learnId={learnId} /> </Box>}


                <Button w={"50%"} href={`#${learnId}`} variant="solid" backgroundColor={"gray.900"} _text={{
                    color: 'white',
                }}

                    _hover={{
                        backgroundColor: "blueGray.500",
                        _text: {
                            color: 'white',
                        }
                    }}
                    mt={2}
                >
                    {learn}
                </Button>
            </VStack>
            <VStack h={{ base: "69%", lg: "100%" }} width={{ base: "100%", lg: "50%" }} >
                <Box  height={isMobile ? "80%" : "100%"} w="100%" position="relative">

                    <Image
                        height="100%"
                        w="100%"
                        source={landingBg}
                        zIndex={1}

                        alt="Sustainable Fashion brings clean ocean" resizeMode="cover"
                    />
                    <Image
                        height="90%"
                        w="100%"
                        source={landingGif}
                        position="absolute"
                        top="5%"
                        left="0"
                        zIndex={10}
                        alt="Sustainable Fashion brings clean ocean" resizeMode="contain"
                    />
                 </Box>


                {isMobile && <Box height="20%" width="100%">
                    <SignUp learnId={learnId} colors={{ submitBtn: colors.secondary }} />
                </Box>}


            </VStack>
        </Flex>

    </Flex>
}

MinimalHero.defaultProps = {
    colors: {
        bg: 'white',
        complementary: 'transparent',
        text: 'black',
    },
    title: "WEAR THE CHANGE: SAVE WATER, CUT CO2, DRESS COOL",
    subscription: "Claim €35 Credit!",
    learn: "Learn more"
}