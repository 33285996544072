import React, { useState, useEffect } from 'react';
import { useConsent, CONSENT_VALUES } from './ConsentContext'
import { Text, Button, Box, useBreakpointValue, Center, VStack } from 'native-base';
import { useLanguage } from './LanguageContext'

import { getLanguageFromUrl } from "./utils";
import translations from './translations.json';

function CookieBanner(props) {
  const { language } = useLanguage();
  const lang = language || getLanguageFromUrl()
  const { text, accept, reject } = translations['Cookie']?.[lang] || props
  const { colors } = props

  const { consent, grantConsent, denyConsent } = useConsent();
  const [showBanner, setShowBanner] = useState(false);


  useEffect(() => {

    // Check if the user has already made a choice regarding cookies
    if (consent === CONSENT_VALUES.GRANTED || consent === CONSENT_VALUES.SKIPPED) {
      setShowBanner(false);
    } else {
      const timer = setTimeout(() => {
        setShowBanner(true);  // This will set showBanner to true after 2 seconds
      }, 3000);
  
      return () => clearTimeout(timer);
    }

  }, [consent]);

  const handleCookies = (accepted) => {
    if (!accepted) {
      denyConsent()
    } else {
      grantConsent()
    }

    setShowBanner(false);
  };

  const buttonSize = useBreakpointValue({ base: 'sm', lg: 'md' })

  if (!showBanner) {
    return null;
  }

  return (

    <>
      {/* Overlay */}
      {/* <Box position="fixed" zIndex="overlay" top="0" left="0" right="0" bottom="0" h="100%" bg="black" opacity={0.5} /> */}

      {/* Banner */}
      <Center position="fixed" zIndex="overlay" bottom="0" left="0" right="0" p="4">
        <Box bg="gray.900" color="white" width="full" shadow="6" rounded="md">
          <VStack space={4} p="4">
            {/* <IconButton 
            size="md"
            P={0}
            _icon={{
              as: FontAwesome5,
              name: "window-close"
            }}
            position="absolute"
             right="0" top="0" 
             alignSelf="end" variant="ghost" 
             colorScheme="black"
              onPress={() => handleCookies(false)}
              /> */}
            <Text textAlign="center" color="white">{text}</Text>
            <Box flexDirection="row" justifyContent="center">
              <Button mr="3" size={buttonSize}
                _text={{ fontWeight: "bold" }}
                backgroundColor={colors.green} onPress={() => handleCookies(true)}>
                {accept}
              </Button>
              <Button size={buttonSize}
                _text={{ color: "gray.800" }}
                bg="gray.200" onPress={() => handleCookies(false)}>
                {reject}
              </Button>
            </Box>
          </VStack>
        </Box>
      </Center>
    </>
  );
}

CookieBanner.defaultProps = {
  colors: {
    main: "cyan.400", // bluish,
    complementary: "#FF6392", // pinkish
  },
  "text": "We value your privacy! Our website uses cookies to ensure you get the best experience. By clicking 'Accept All', you consent to our use of cookies. If you prefer, click 'Reject All' to disable non-essential cookies.",
  "accept": "OK",
  "reject": "Reject All"
}

export default CookieBanner;
