import React from 'react';
import { Box, Image, Text, Flex, Heading, VStack, useBreakpointValue, Spacer } from 'native-base';

import { useLanguage } from './LanguageContext'

import { getLanguageFromUrl } from "./utils";
import translations from './translations.json';

export default function EcoComponent(props) {
    const flexDir = useBreakpointValue({
        base: "column",
        lg: "row"
    });

    
    const { language } = useLanguage();
    const lang = language || getLanguageFromUrl()
    const { items, title } = translations['Eco']?.[lang] || props
    const { colors } = props

    const images = [
        require('./assets/landing/eco-dress.webp'),
        require('./assets/landing/eco-table.webp'),
        require('./assets/landing/achievements.webp'),
    ]

    return (
        <VStack alignItems="center" w="100%" h="100%">
        
            <Heading color={colors.heading} fontSize={{ base: 20, lg: 30 }}
                bg={colors.bgText} mb={10} p={{ base: 2, lg: 5 }}
                textAlign='center'
                w={{ base: "100%", lg: "50%" }}
                fontWeight="500">{title}
            </Heading>

            <Flex direction={flexDir} w="100%" space={4} minH={{ base: 500, lg: 550 }} justifyContent="center" alignItems="center">
                {items.map((item, index) => (
                    <Box
                        key={index}
                        flex={1}
                        rounded="2xl"
                        overflow="hidden"
                        backgroundColor="white"
                        borderColor="coolGray.300"
                        borderWidth="1px"
                        boxShadow="sm"
                        maxW={400}
                        minW={{ base: "70%", lg: "25%" }}
                        minH={{ base: 300, lg: 400 }}
                        marginRight={{ base: 0, lg: 8 }}
                        marginBottom={{ base: 8, lg: 0 }}
                    >
                        <Image
                            resizeMode="contain"
                            source={images[index]}
                            alt={item.alt}
                            h={{ base: 260, lg: 360 }}
                        />
                        <Box h={10} bg="gray.800" justifyContent="center" position="absolute" bottom={0} width="100%">
                            <Text
                                fontSize={{ base: 12, lg: 12 }}

                                color="white" fontWeight="bold" textAlign="center">{item.title}</Text>
                        </Box>
                    </Box>

                ))}
            </Flex>
        </VStack>
    );
}

EcoComponent.defaultProps = {
    colors: {
        heading: 'tertiary.500',
        bgHeading: 'transparent'
    },
    title: "Stay aware of your enviromental impact",
    items: [
        {
            alt: 'Clothes environmental impact',
            title: 'Every piece comes with enviromental impact'
        },
        {
            alt: 'Eco leaderboard',
            title: 'Get to know your local heroes'
        },
        {
            alt: 'Eco dashboard',
            title: 'Get awareness on your eco contribution'
        }
    ]
}
