import React from "react";
import {  Picker,StyleSheet,  } from 'react-native';

import {
  useBreakpointValue,
  HStack, Box, Button, Icon, Flex, Text
} from "native-base";
import { FontAwesome5 } from '@expo/vector-icons';

import { useLanguage } from './LanguageContext';

const styles = StyleSheet.create({
  picker: {
    width: '100%',
    height: "100%",
    backgroundColor: 'transparent',
    color: 'black',

  },
  pickerItem: {
    color: 'black',
    backgroundColor: 'black',
  }
});

const LanguageDropdown = () => {
  
  const { language, changeLanguage } = useLanguage();
  return<Picker
      selectedValue={language}
      style={styles.picker}
      onValueChange={(itemValue) => changeLanguage(itemValue)}

    >
      <Picker.Item label="EN" value="en" color="black"  />
      <Picker.Item label="DE" value="de" color="black"/>
      <Picker.Item label="NL" value="nl"  color="black"/>
    </Picker>
};


const HeaderComponent = (props) => {
  const { subsId, learnId, contactCallBack, colors } = props
  const btnSize = useBreakpointValue({ base: "sm", md: "lg" });
  const topBarHeight = useBreakpointValue({ base: "46px", lg: "72px" });
  const brandFontSize = useBreakpointValue({ base: 30, lg: 50 })
  const textColor = "gray.900"
  return (
    <Flex flexDirection="row" pl={2} alignItems="center" justifyContent="space-between" py={4} pr={4} bg={colors.main} height={topBarHeight}>

      <Button flex={1}
        justifyContent="flex-start"

        href={`#${learnId}`} variant="ghost"
        _text={{
          color: textColor,
          fontFamily: 'DancingScript_700Bold',
          fontSize: brandFontSize
        }}
        px={0}
      >
        Stylu
      </Button>


      <HStack flex={2} justifyContent="flex-end">
        <Box w={{ base: "35%", lg: "15%" }} p={0} h="100%" mr={{ base: 2, lg: 5 }} 
        alignSelf="center"
        >
          <LanguageDropdown />

        </Box>


        {/* <Button size={btnSize} variant="outline" borderColor={textColor} _text={{ color: textColor }}
          leftIcon={<Icon as={FontAwesome5} name="envelope" size={btnSize} color={colors.main || textColor} />}
          onPress={() => contactCallBack()}
        >
          Contact
        </Button> */}
        <Button size={btnSize} href={`#${subsId}`} variant="outline" borderColor={textColor} _text={{
          color: textColor,
          fontWeight: "bold"
        }}
          leftIcon={<Icon as={FontAwesome5} name="feather-alt" size={btnSize} color={colors.green || 'green.300'} />}
        >
          Claim €35 Credits!
        </Button>
      </HStack>
    </Flex>
  );
};

export default HeaderComponent