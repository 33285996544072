import React, { useState, useEffect } from 'react';
import {
  NativeBaseProvider, extendTheme, View
} from "native-base";

import {
  useFonts as popUseFonts,
  Poppins_300Light,
  Poppins_400Regular,
  Poppins_600SemiBold,
  Poppins_800ExtraBold,
} from '@expo-google-fonts/poppins';

import {
  useFonts as dancingUseFonts,
  DancingScript_700Bold
} from '@expo-google-fonts/dancing-script';

import { LinearGradient } from 'expo-linear-gradient';

import './firebase'
import { setupScrollTracking, cleanScrollListener } from './collector'
//import { getQueryParam } from './utils'
import MinimalLanding from './MinimalLanding'
import { CONSENT_VALUES, ConsentProvider, useConsent } from './ConsentContext';
import { LanguageProvider, useLanguage } from './LanguageContext';


// This was to dynamically load landing page. Not needed anymore
// export function App() {
//   const [ClassicLanding, setClassicLanding] = useState(null);

//   const variation = getQueryParam('var');

//   useEffect(() => {
//     async function loadComponent() {
//       if (variation === 'classic') {
//         const { default: ClassicComponent } = await import('./ClassicLanding');
//         setClassicLanding(() => ClassicComponent);
//       }
//     }

//     loadComponent();
//   }, [variation]);

//   if (variation === 'classic' && !ClassicLanding) {
//     return null; // Or a loading spinner
//   }

//   return (
//     <View h="100%" w="100%">
//       {variation === 'classic' ? <ClassicLanding /> : <MinimalLanding />}
//     </View>
//   );
// }

export function App(params) {

  const { consent } = useConsent();


  useEffect(() => {
    let listener
    if (consent === CONSENT_VALUES.GRANTED || consent ===  CONSENT_VALUES.SKIPPED) {
      listener = setupScrollTracking();
      return () => cleanScrollListener(listener)
    }
  }, [consent]);

  return (
    <View h="100%" w="100%">
      <MinimalLanding />
    </View>
  );
}

export default function AppWrapper() {

  let [popFontsLoaded] = popUseFonts({
    Poppins_300Light,
    Poppins_400Regular,
    Poppins_600SemiBold,
    Poppins_800ExtraBold,
  });

  let [dancingFontsLoaded] = dancingUseFonts({
    DancingScript_700Bold
  });

  const theme = extendTheme({
    fontConfig: {
      Poppins: {
        100: {
          normal: "Poppins_300Light",
        },
        200: {
          normal: "Poppins_300Light",
        },
        300: {
          normal: "Poppins_400Regular",
        },
        400: {
          normal: "Poppins_400Regular",
        },
        500: {
          normal: "Poppins_400Regular",
        },
        600: {
          normal: "Poppins_600SemiBold",
        },
        700: {
          normal: "Poppins_600SemiBold",
        },
        800: {
          normal: "Poppins_800ExtraBold",
        },
      },
    },
    fonts: {
      heading: "Poppins",
      body: "Poppins",
      mono: "Poppins",
    },
  });

  if (!popFontsLoaded || !dancingFontsLoaded) {
    return null;
  }

  const appConfig = {
    dependencies: {
      'linear-gradient': LinearGradient
    }
  };



  return (<NativeBaseProvider theme={theme} config={appConfig}>
    <LanguageProvider>
      <ConsentProvider>
        <App />
      </ConsentProvider>
    </LanguageProvider>
  </NativeBaseProvider>
  );
}