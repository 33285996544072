import React from 'react';
import { logEvent } from "firebase/analytics";
import { analytics } from './firebase';

export const trackSubmission = (id) => {
  try {
    logEvent(analytics, 'form_submit', {
      form_id: id,
    })
  } catch (e) {
    console.error(e)
  }
}

export const reportSubmissionError = (id) => {
  try {
    logEvent(analytics, 'form_error', {
      form_id: id,
    })
  } catch (e) {
    console.error(e)
  }
} 

export const reportTouched = (id) => {
  try {
    logEvent(analytics, 'form_start', {
      form_id: id,
    })
  } catch (e) {
    console.error(e)
  }
} 

const FormWrapper = ({ children, onSubmit, id }) => {

  // TODO this is useless and submission is happening by a button outside of this 
  const submitHook = (event) => {
    event.preventDefault();

    onSubmit()
    trackSubmission(id)
  }

  return (
    <form onSubmit={submitHook} id={id} style={{
      width: "100%",
      "alignItems": "center",
      display: "flex",
      "flexDirection": "column"
    }}>
      {children}
    </form>
  );
};

export default FormWrapper;
