import React, { useState } from "react";
import {
    Button,
    VStack, Text, Heading, Input, Box, HStack, FormControl
} from "native-base";
import { subscribe } from "./firebase";
import FormWrapper, { trackSubmission, reportSubmissionError, reportTouched } from './FormWrapper'
import { LinearGradient } from 'expo-linear-gradient';

import { customColors, getLanguageFromUrl, validateEmail } from "./utils";
import translations from './translations.json';
import { useLanguage } from './LanguageContext'

const YearDisplayCard = () => {
    const yearDigits = ['2', '0', '2', '4']; // The digits for the year 2024

    return (
        <Box alignItems="center" p="5">
            <VStack space={2} alignItems="center">
                <HStack space={2}>
                    {yearDigits.map((digit, index) => (
                        <Box
                            key={index}
                            bg="warmGray.800" // Dark gray background
                            width="40px" // Width of each digit box
                            height="60px" // Height of each digit box
                            alignItems="center"
                            justifyContent="center"
                            rounded="sm"
                        >
                            <Text color="white" fontSize="2xl" bold>
                                {digit}
                            </Text>
                        </Box>
                    ))}
                </HStack>
            </VStack>
        </Box>
    );
};

export default function Subscription(props) {
    
    const { language } = useLanguage() || props.lang || { };
    const lang = language || getLanguageFromUrl()

    const { title, subscription, campaignMsg, reward } = translations['Subscription']?.[lang] || props
    const { colors, id, callBack } = props

    const [socialId, setSocialId] = useState('');
    const [inputErr, setInputErr] = useState('')
    const [touched, setTouched] = useState(false)
    const maxChar = 50

    const onInputChange = (txt) => {

        if (!touched) {
            setTouched(true)
            reportTouched(id)
        }

        let isValid = txt?.length < maxChar;
        let error = 'Too long! Max char length ' + maxChar
        isValid = validateEmail(txt);
        error = 'Not a valid email address'

        if (!isValid) {
            setInputErr(error)
        } else {
            setInputErr('')
        }
        setSocialId(txt)
    }

    const handleSubmit = async (event) => {
        event.preventDefault();

        // Check if all values are provided
        if (!socialId.trim()) {
            alert('Please fill out all fields.');
            return;
        }

        if (inputErr) {
            alert('Please fix your input ' + inputErr)
        }

        try {
            let adaptedSocialId = socialId.substring(0, maxChar) // just the first maxChar chars
            await subscribe(adaptedSocialId, "email")
            trackSubmission(id)
            alert(`Succesfuly subcribed ${socialId} to Stylu!`)
            setSocialId('')
            callBack(true)
        } catch (error) {
            console.error("Error writing document: ", error);
            reportSubmissionError(id)
            callBack(false)
            alert('Oops! Something went wrong. Please reach us at info@stylu.de');
        }
    };

    return <LinearGradient
        colors={[colors.bg, colors.bgFade]} // Adjust the colors as needed
        start={{ x: 0, y: 0 }} // Starting point of gradient
        end={{ x: 1, y: 1 }} // Ending point of gradient
        style={{ flex: 1 }}
    >
        <VStack
            nativeID="subscription"
            alignSelf="center" alignItems="center" p={{ base: 5, lg: 10 }}

        >
            <Heading color={colors.heading} p={2} fontSize={{ base: 18, lg: 35 }} textAlign="center"
                fontWeight="700">{title}
            </Heading>
            <YearDisplayCard />

            <Heading color={colors.reward} mb={2} fontSize={{ base: 20, lg: 40 }} textAlign="center"
                fontWeight="700">{reward}
            </Heading>
            <FormWrapper id={id} onSubmit={handleSubmit}>

                <FormControl isInvalid={inputErr} w="75%" maxW="300px">

                    <Input
                        _focus={{ borderColor: 'tertiary.500' }}
                        _hover={{ borderColor: customColors.khaki }}
                        color={colors.input}
                        borderColor={colors.input}
                        placeholderTextColor={colors.input}
                        value={socialId}
                        onChangeText={onInputChange}
                        size={{ base: "lg", lg: "2xl" }} width="100%" placeholder="email" />

                    <FormControl.ErrorMessage>
                        {inputErr}
                    </FormControl.ErrorMessage>
                </FormControl>

                <Button
                    mt={{ base: 2, lg: 5 }}
                    _text={{
                        color: colors.buttonText,
                        bold: true,
                        fontSize: { base: "13px", sm: "md" },
                        numberOfLines: 2
                    }}
                    _hover={{
                        backgroundColor: "blueGray.600",
                        _text: { color: colors.button } // Text color on hover
                    }}
                    type="submit"
                    bg={colors.button}
                    px={{ base: 1, lg: 8 }}
                    width={{ base: "100%", lg: "50%" }}
                    height={"50"}
                    isDisabled={inputErr}
                    onPress={handleSubmit}>
                  
                        {subscription}
                </Button>
            </FormWrapper>
            <Text
                mt={{ base: 3, lg: 5 }}
                textAlign="center"
                fontSize={{ base: "xs", lg: "sm" }} fontWeight="500"
                color={colors.text}>
                {campaignMsg}
            </Text>
        </VStack>
    </LinearGradient>
}

Subscription.defaultProps = {
    subscription: 'Subscribe',
    title: 'Subscribe to be the first!',
    id: 'default',
    campaignMsg: 'Join to get our exlusive Vintage sales in your city',
    colors: {
        heading: 'white',
        reward: 'white',
        bg: 'tertiary.500',
        bgFade: "#22d3ee",
        input: 'white',
        button: customColors.rosi,
        buttonText: "white",
        text: 'white'
    },
    callBack: () => {}
}